.va-lower-banner {
  width: 100%;
  background-color: #ffffff;
  padding: 30px 64px 0px 64px;
}

.va-main-container {
  width: 100%;
  background-color: #F7F7F7;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: 0 auto;
}

.card-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.card {
  position: absolute;
  transition: all 0.5s ease;
  border-radius: 10px;
  overflow: hidden;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card.center {
  z-index: 2;
}

.card.left {
  transform: translateX(-25%) scale(1);
  z-index: 1;
}

.card.right {
  transform: translateX(30%) scale(1);
  z-index: 1;
}

.card-slider:hover .card.left {
  transform: translateX(-70%) scale(0.97);
}

.card-slider:hover .card.right {
  transform: translateX(70%) scale(0.98);
}

.card-slider:not(:hover) .card {
  transition: all 0.5s ease;
}

.card-slider:hover .card.center {
  transform: scale(1.05);
}

.va-banner {
  width: 100%;
  background-color: #ffffff;
  padding: 30px 64px 0px 64px;
}

.input-group {
  display: flex;
  justify-content: center;
}

.va-upper-banner {
  padding: 40px 64px 0 64px;
  text-align: center;
}

.va-header {
   display: flex;
    width: 45%;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
}
@media screen and (max-width: 767px) {
  .va-header {
    width: 100%;
  }
}
.light-text {
  font-weight: 400;
}

.bold-text {
  font-weight: 600;
}

.va-title {
  font-size: 30px;
 font-family: 'Noto Sans';
 line-height: 38px;
 color: #14151C;
 margin-bottom: 10px;
 line-height: -1.25%;
}
@media screen and (max-width: 767px) {
  .va-title {
    font-size: 24px;
    line-height: 32px;
  }
}
.va-subtitle { 
 font-size: 14px;
 font-weight: 400;
 font-family: 'Noto Sans';
 line-height: 20px;
 color: #4D5165;
 margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .va-subtitle {
    font-size: 12px;
  }
}
.va-report-input-section {
  width: 30%;
}

.va-upper-banner-main-container {
  display: flex;
  width: 100%;
  background-color: #DFFBE8;
  padding: 24px 64px;
  margin-top: -10px;
}

@media screen and (max-width: 767px) {
  .va-upper-banner-main-container {
    padding: 24px 16px;
  }
}

.va-upper-right-section {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  font-family: 'Noto Sans';
}

@media screen and (max-width: 767px) {
  .va-upper-right-section {
    margin-left: 14px;
  }
}

.va-upper-right-section-title {
  display: flex;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1.25%;
  color: #14151C;
  margin-bottom: 8px;
}

.va-upper-right-section-text-one {
 display: flex;
 font-size: 14px;
 font-weight: 400;
 line-height: 20px;
 letter-spacing: -0.75%;
 color: #696F88;
 margin-bottom: 8px;
}
.va-upper-right-section-text-two {
  display: flex;
  margin-bottom: 8px;
}
.note-text {
  display: flex;
  margin-left: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  font-style: italic;
  color: #C17E19;
  margin-top: 2px;
}

.va-upper-right-section-input-button {
  display: flex;
  margin-top: 24px;
}

.va-upper-right-section .input-group {
  justify-content: left;
}

.va-upper-right-section .va-report-input-section {
  width: 66%;
}

.carousel .slide:not(.selected) img {
  transform: scale(0.93) rotateY(-10deg);
}

.carousel .slide.selected img {
  transform: scale(1.05) rotateY(-10deg);
}

.card-slider-container .carousel.carousel-slider .control-arrow {
  visibility: collapse;
}

.card-slider-container .carousel-root {
  margin-bottom: -30px;
}